<template>
  <div class="login-container">
    <div class="banner">
      <div class="banner_img">
        <h2>账号绑定</h2>
      </div>
    </div>
    <div class="login-form">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="rules"
      >
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="loginForm.username"
            :placeholder="$t('L_LOGIN.LOGIN_0001')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            ref="password"
            v-model="loginForm.password"
            :placeholder="$t('L_LOGIN.LOGIN_0002')"
            @keyup.enter.stop="handleLogin"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="logining"
            @click.native.prevent="handleLogin"
            >确认绑定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import {
  getCurrentUser,
} from "@/api/login";
import md5 from "js-md5";
import {weixinLogin} from "../../../api/login";
export default {
  name: "login",
  data() {
    let validateUserName = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9\u4E00-\u9FA5_]{4,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0001")));
      } else {
        return callback();
      }
    };
    let validatePassword = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{5,20}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_LOGIN.LOGIN_0002")));
      } else {
        callback();
      }
    };
    return {
      // 表单参数
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: validatePassword,
            trigger: "blur",
          },
        ],
      },
      logining: false,
      openId: undefined
    };
  },
  computed: {
    language() {
      let language = this.$i18n.locale;
      return language;
    },
  },
  mounted() {
    this.getOpenId()
  },
  methods: {
    getOpenId() {
      this.openId = this.$route.params.data.openId;
    },
    // 密码登录
    handleLogin() {
      this.handleStoreToken(null);
      this.handleStore("");
      this.logining = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let param = {
            username: this.loginForm.username.trim(),
            password: md5(this.loginForm.password).toUpperCase(),
            openId: this.openId,
            type: Number(1)
          };
          weixinLogin(param)
            .then((response) => {
              if (response.code === 200) {
                this.handleStoreToken(response.token);
                getCurrentUser()
                  .then((res) => {
                    if (res.code === 200) {
                      this.logining = false;
                      let data = res.data;
                      this.handleStore(data);
                      let userLogin = JSON.stringify({
                        username: param.username,
                        password: param.password,
                        projid: 0,
                      });
                      localStorage.setItem("userLogin", userLogin);
                      this.$router.push({ path: "/" });
                    } else {
                      if(res.msg) {
                        this.loginFailed(res.msg);
                      } else {
                        this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                      }
                    }
                  })
                  .catch(() => {
                    this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                  });
              } else {
                if(response.msg) {
                  this.loginFailed(response.msg);
                } else {
                  this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
                }
              }
            })
            .catch(() => {
              this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
            });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.logining = false;
        }
      });
    },
    loginFailed(text) {
      this.logining = false;
      this.resetForm1();
      this.handleMessaage(text, text);
    },
    handleMessaage(text1, text2) {
      this.$message({
        message: this.language === "en" ? text1 : text2,
        type: "error",
        offset: 60,
      });
    },
    resetForm1() {
      if (this.$refs.loginForm !== undefined) {
        this.loginForm = {
          username: "",
          password: "",
        };
        this.$refs.loginForm.clearValidate();
      }
    },
    handleStore(info) {
      this.$store.commit("setUserInfo", info);
    },
    handleStoreToken(token) {
      this.$store.commit("setToken", token);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/login";
</style>